<template>
  <div>
    <!-- <img
      :src="imageUrl"
      alt=""
    > -->
    <view-abnormal :imageUrl="imageUrl" status="404" tips="抱歉，您访问的页面不存在" />
  </div>

  <!-- <a-result
    status="404"
    title="404"
    sub-title="抱歉，您要访问的页面不存在。"
  > -->
  <!-- <template #extra>
      <a-button
        type="primary"
        @click="toHome"
      >
        返回首页
      </a-button>
    </template>
  </a-result>-->
</template> 

<script>
import ViewAbnormal from './modules/view-abnormal'

export default {
  components: {
    ViewAbnormal
  },
  data() {
    return {
      imageUrl: require('@/assets/images/404.svg')
    }
  },
  methods: {
    toHome() {
      this.$router.push({ path: '/' })
    }
  }
}
</script>
